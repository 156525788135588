import React from 'react';
import { Box, Grid, Popover, Typography } from '@material-ui/core';
import styles from './caseStudies.styles';

const CaseStudies = ({
  anchorEl,
  popNum,
  handlePopoverClose,
  handlePopoverOpen,
  open,
}) => {
  return (
    <Box css={styles}>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="cs-top"
        spacing={6}
      >
        <Grid item xs={12} className="popover-anchor">
          <Typography variant="h5" component="h1" className="cs-header">
            Case Studies
          </Typography>
          <Box>
            <Box
              className="tooltip-container casestudy"
              pt={3}
              aria-owns={open ? 'mouse-over-popover10' : undefined}
              aria-haspopup="true"
              onMouseEnter={(e) => handlePopoverOpen(e, 10)}
              onMouseLeave={handlePopoverClose}
            >
              <img
                src={`/images/questionmark.png`}
                className="help-icon help-icon-about"
              />
            </Box>
            {popNum === 10 && (
              <Popover
                id="mouse-over-popover10"
                style={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                // anchorReference="anchorPosition"
                // anchorPosition={{ top: 80, left: 100 }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Box p={3}>
                  <Typography variant="body1">
                    It always helps to go a bit deeper on a few key projects.
                    What problems were being solved, what steps were taken, what
                    research, how the process went, and of course the results.
                  </Typography>
                </Box>
              </Popover>
            )}
          </Box>
        </Grid>
        <Grid item xs={10} sm={7}>
          <img src={`/images/star-purple.svg`} />
          <Typography variant="body1" className="cs-copy">
            Case studies are a must. We'll be providing some detailed info on
            what to include soon. For now,{' '}
            <a
              href="https://blog.uxfol.io/product-designer-case-studies/"
              target="_blank"
              rel="noopener"
              className="purple-underline-link"
            >
              this link
            </a>{' '}
            does a pretty good job. Stay tuned.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={8} className="pf-item-container">
        <Grid item xs={10} md={5}>
          <img
            src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
            className="gray-img"
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <Typography variant="body1">
            <span className="steps">Step 1:</span>Lorem ipsum is placeholder
            text commonly used in the graphic, print, and publishing industries
            for previewing layouts and visual mockups. Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups. Commonly used
            in the graphic, print, and publishing industries
          </Typography>
        </Grid>
        <Grid item xs={10} md={5}>
          <img
            src="https://images.unsplash.com/photo-1586296835409-fe3fe6b35b56?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1567&q=80"
            className="gray-img"
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <Typography variant="body1">
            <span className="steps">Step 2:</span>Lorem ipsum is placeholder
            text commonly used in the graphic, print, and publishing industries
            for previewing layouts and visual mockups. Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups. Commonly used
            in the graphic, print, and publishing industries
          </Typography>
        </Grid>
        <Grid item xs={10} md={5}>
          <img
            src="https://images.unsplash.com/photo-1618044733300-9472054094ee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1351&q=80"
            className="gray-img"
          />
        </Grid>
        <Grid item xs={10} md={5}>
          <Typography variant="body1">
            <span className="steps">Step 3:</span>Lorem ipsum is placeholder
            text commonly used in the graphic, print, and publishing industries
            for previewing layouts and visual mockups. Lorem ipsum is
            placeholder text commonly used in the graphic, print, and publishing
            industries for previewing layouts and visual mockups. Commonly used
            in the graphic, print, and publishing industries
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudies;
