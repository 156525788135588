import { css } from '@emotion/react';

const styles = (theme) => css`
  border: 2px solid ${theme.palette.text.primary};
  border-radius: 72px;
  margin-top: 5rem;

  .cs-top {
    text-align: center;
    justify-content: center;
  }

  .casestudy {
    top: 23%;
    width: auto;
    right: 30%;
  }

  .cs-header {
    border-bottom: 2px solid ${theme.palette.text.primary};
    padding: 4rem 0;
    position: relative;
  }

  .cs-copy {
    margin: 2.5rem 0 6rem;
  }

  .pf-item-container {
    justify-content: center;
    padding-bottom: 6rem;
    img {
      max-width: 100%;
    }
  }
  .purple-underline-link {
    border-bottom: 3px solid #bb9cfe;
    text-decoration: none;
    line-height: 1.1;
    color: inherit;
    display: inline-block;
    transition: 0.25s ease;
    &:hover {
      color: #bb9cfe;
      transition: 0.25s ease;
    }
  }
  .steps {
    font-weight: bold;
    margin-right: 6px;
  }
  .gray-img {
    filter: grayscale(100%);
  }
`;

export default styles;
