import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Footer from '../components/footer';
import PortfolioComponent from '../portfolio/';
import { SEO } from '../components/seo';

export const FOOTER_QUERY = graphql`
  query footerQuery {
    contentfulFooter {
      logo {
        file {
          url
        }
      }
      logoCaption
      footerLinks {
        id
        linkText
        linkUrl
      }
    }
  }
`;

const Portfolio = ({ data }) => {
  const { contentfulFooter } = data;
  return (
    <Layout>
      <PortfolioComponent />
      <Footer footer={contentfulFooter} />
    </Layout>
  );
};

export default Portfolio;

export const Head = () => <SEO />;
